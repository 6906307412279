<template>
  <div class="page">
    <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center"><a
        name="_055-1599121612466"></a><span style="font-size:11pt">&#xa0;</span></p>
    <p style=" margin:0pt 0pt 20pt; text-align:center"><a name="_788-1601185948581"></a><span
        style="font-size:24pt; font-weight:bold">&#xa0;</span><span
        style="color:#3a4049; font-size:24pt; font-weight:bold">ITGSA数据使用、隐私与个人信息保护协议</span>
    </p>
    <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt"><a name="_670-1607072676412"></a><span
        style="font-size:11pt">&#xa0;</span></p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_070-1607072663685"></a><span
        style="color:#595959; font-size:12pt">1.开发者同意，其产品应采取最小权限原则，调用最小范围内的权限，调用仅为实现本业务需求的功能。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_699-1607072663690"></a><span
        style="color:#595959; font-size:12pt">2.为了满足《中华人民共和国网络安全法》、《电信和互联网用户个人信息保护规定》及个人信息保护相关配套措施的规定，开发者涉及处理用户个人信息的，应设有隐私政策或以其他方式明示告知用户拟收集的个人信息和使用方式、目的及必要性等，并获得用户同意（针对个人信息）或者明示同意（针对个人敏感信息）之后予以采集和处理，以保障用户的知情权。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_227-1607072663694"></a><span
        style="color:#595959; font-size:12pt">个人信息，是指以电子或其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_490-1607072663698"></a><span
        style="color:#595959; font-size:12pt">明示同意是指，用户通过书面声明或主动做出肯定性动作，对其个人信息进行特定处理做出明确授权的行为。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_510-1607072663702"></a><span
        style="color:#595959; font-size:12pt">3.若开发者产品涉及调用敏感接口（包括但不限于拨打电话、短信、彩信、WLAN、蜂窝网络连接、NFC、通讯录、通话记录、位置、录音录像等）、信息通知与推送、广告营销等功能时，均应有明显提示，并经过用户的主动同意和确认，同时提供具体的撤回管理选项。联盟有权采取技术手段保留用户对上述业务和功能主动同意和确认的记录，以便法律规定要求时必要调取。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_960-1607072663708"></a><span
        style="color:#595959; font-size:12pt">4.开发者对收集的数据予以分开存储，并在独立的计算机集群中进行处理。除非获得用户（若涉及用户个人信息）和联盟的书面同意，开发者不得将收集的数据与开发者其他来源数据进行任何形式的汇聚和/或综合分析。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_095-1607072663712"></a><span
        style="color:#595959; font-size:12pt">5.开发者应仅基于实现产品服务之目的而使用采集的数据，未经联盟和用户（若涉及到用户个人信息）书面/明示同意，开发者不得将收集的数据用于其他任何使用目的或披露给任何第三方。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_317-1607072663718"></a><span
        style="color:#595959; font-size:12pt">6.开发者同意，对采集数据及数据的处理行为的合法性、正当性和必要性承担法律责任，保证数据的采集、使用、分享符合《网络安全法》、《信息安全技术个人信息安全规范》等现行生效的相关法律、行政法规、国家标准的要求。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_986-1607072663724"></a><span
        style="color:#595959; font-size:12pt">7.开发者同意，在终止产品服务后或在相关法规规定的保存期限结束后对收集的个人信息予以删除或匿名化处理。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_024-1607072663729"></a><span
        style="color:#595959; font-size:12pt">8.开发者同意，对于发现或用户投诉开发者存在非法处理数据的情形，联盟有权立即下架开发者的产品、断开链接，并要求开发者应及时响应采取补救措施予以解决，对联盟及用户造成的损失，开发者应予以赔偿。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_157-1607072663735"></a><span
        style="color:#595959; font-size:12pt">9.开发者承诺：其产品不含有病毒、木马等恶意代码或功能，不存在窃取用户个人信息、远程控制用户终端、恶意扣费、恶意传播、破坏用户终端系统、欺诈诱骗、流氓行为等恶意行为；不会利用系统漏洞，实现提升应用权限、执行恶意代码、获取用户数据等恶意行为。</span>
    </p>
    <p style="font-size:12pt; line-height:167%; margin:0pt 0pt 10pt"><a name="_665-1607072663749"></a><span
        style="color:#595959; font-size:12pt">10.开发者承诺：数据原则上在中华人民共和国大陆境内处理。如涉及数据跨境的，应当不违背现行生效法律法规规定，并按照具体要求完成流程备案或审核，并签署具体的授权文件或协议。</span>
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 隐私与个人信息保护协议',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,金标认证'
      },
      {
        name: 'description',
        content: '移动智能终端生态联盟是国内领先的行业非盈利性组织，由领先的智能终端厂商联合发起和建立，旨在推动全行业应用生态标准化、规范化、合规化、高效化，提升全行业应用服务水平和能力，促进行业共赢、共发展。'
      },
    ]
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.page {
  padding: 2% 3% 2% 6%;
  h3 {
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 10px;
  }
  p {
    font-size: 13px;
    width: 100%;
    font-weight: 300;
    color: #333333;
    line-height: 30px;
  }
}
table{
  td{
    padding: 10pt;
  }
}
</style>
